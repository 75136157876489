import { Box, Card, Grid, LinearProgress, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import cookie from 'cookie'
import { SSRKeycloakProvider, SSRCookies } from '@react-keycloak/ssr'
import keycloak from "../../../keycloak";
import Keycloak from '../../app/keycloak';
import { ReactKeycloakProvider, useKeycloak } from "@react-keycloak/web";
import { useAtom } from "jotai";
import { baseAtom } from "../../../stores/jotai/base";
import { useRouter } from "next/router";

const tokenLogger = (tokens: unknown) => {
    //
}

export const MAX_LOADING_STATES = 4;

// key, anchor, open, setIsOpen are required
function GenericPage(props) {
    const [base, setBase] = useAtom(baseAtom);
    const [authenticated, setAuthenticated] = useState(false);
    const [authenticationState, setAuthenticationState] = useState(0);
    const [loadingStatus, setLoadingStatus] = useState('Getting your account information...');
    const router = useRouter();

    const eventLogger = (event: unknown, error: unknown) => {
        console.log(`keycloak event (${JSON.stringify(event)})`);

        if (JSON.stringify(event) === `"onInitError"`) {
            router.push("/maintenance-error");
        }
    };

    return <ReactKeycloakProvider
        authClient={keycloak}
        onEvent={eventLogger}
        onTokens={tokenLogger}
    >
        <Keycloak
            org={base.org}
            user={base.user}
            cohort={base.cohort}
            pageRequest={base.pageRequest}
            loginStatusUpdate={(result) => {
                try {
                    setAuthenticationState(result.loadingState);
                    setLoadingStatus(result.loadingStatus);
                }
                catch (e) {

                }
            }}
            baseUpdate={(payload) => {
                try {
                    if (payload.org.orgID.length > 0) {
                        setAuthenticated(true);
                        //setBase(payload);
                    }
                }
                catch (e) { }

                // setState(
                //     {
                //         baseAtomBridge: payload,
                //         hierarchy: payload.hierarchy,
                //         org: payload.org,
                //         user: payload.user,
                //         members: payload.members,
                //         findings: payload.findings,
                //         findingTypes: payload.findingTypes,
                //         note: payload.note,
                //         transcript: payload.transcript,
                //         media: payload.media,
                //         wiki: payload.wiki,
                //         interviews: payload.interviews,
                //         appState: getAppState(payload),
                //         team: payload.team,
                //         cohort: payload.cohort,
                //         canvas: payload.canvas,
                //         teams: payload.teams,
                //         cohorts: payload.cohorts,
                //         notifications: []
                //     });
            }}
            team={base.team} appState={base.app} children={props.children} />
    </ReactKeycloakProvider>
}

export default GenericPage;
