import { noop } from "lodash";
import { APP_RETRY_TIMER } from "../constants";

export const APP_LOADING_RETRY_COUNT_KEY = "appLoadingRetryCount";
export const APP_LOADING_RETRY_COUNT_LIMIT_KEY = "appLoadingRetryCountLimit";
export const APP_LOADING_RETRY_TIMER_ID = "appLoadingRetryTimerID";

export const setAppLoadingRetryCount = (value) => {
    localStorage.setItem(APP_LOADING_RETRY_COUNT_KEY, value);
}

export const setAppLoadingRetryTimerID = (value) => {
    localStorage.setItem(APP_LOADING_RETRY_TIMER_ID, value);
};

export const setAppLoadingRetryCountLimit = (value) => {
    localStorage.setItem(APP_LOADING_RETRY_COUNT_LIMIT_KEY, value);
};

export const getAppLoadingRetryCount = () =>{
    if (typeof localStorage !== "undefined") {
        const currentRetryCount = localStorage.getItem(
            APP_LOADING_RETRY_COUNT_KEY
        );
        
        return currentRetryCount ? parseInt(currentRetryCount, 10) : 0;
    }

    return 0;
}

export const getAppLoadingRetryTimerID = () => {
    if (typeof localStorage !== "undefined") {
        const currentRetryTimerID = localStorage.getItem(APP_LOADING_RETRY_TIMER_ID);

        return currentRetryTimerID || null;
    }

    return null;
};

export const getAppLoadingRetryCountLimit = () => {
    if (typeof localStorage !== "undefined") {
        const currentRetryCountLimit = localStorage.getItem(APP_LOADING_RETRY_COUNT_LIMIT_KEY);

        return currentRetryCountLimit ? parseInt(currentRetryCountLimit, 10) : 0;
    }

    return 0;
};

export const increaseAppLoadingRetryCountBy = (value) => {
    const currentRetryCount = getAppLoadingRetryCount();
    const newRetryCount = currentRetryCount + value;

    setAppLoadingRetryCount(newRetryCount);

    return newRetryCount;
}

export const handleAppLoadingRetryCycle = ({ retryCount, onIntervalEnd, onRetryEnd }) => {
    const newRetryCount = increaseAppLoadingRetryCountBy(1);

    if (newRetryCount > retryCount) {
        onRetryEnd();
    } else {
        onIntervalEnd();
    }
}

export const stopAppLoadingRetryTimer = () => {
    const currentRetryTimerID = getAppLoadingRetryTimerID();
    
    if (currentRetryTimerID !== "null") {
        clearInterval(currentRetryTimerID);
        setAppLoadingRetryTimerID(null);
        setAppLoadingRetryCount(0);
    }
}

export const startAppLoadingRetryTimer = ({
    interval = APP_RETRY_TIMER.INTERVAL,
    retryCount = APP_RETRY_TIMER.RETRY_COUNT,
    onIntervalEnd = noop,
    onRetryEnd = noop,
}) => {
    const newTimerID = setInterval(() => {
        handleAppLoadingRetryCycle({
            retryCount,
            onIntervalEnd,
            onRetryEnd,
        });
    }, interval);

    setAppLoadingRetryTimerID(newTimerID);
    setAppLoadingRetryCountLimit(retryCount);
};
